<template>
  <div>
  	<b-card
      no-body
      class="border p-2"
    >
	    <!-- Alert: No item found -->
	    <b-alert
	      variant="danger"
	      :show="moduleData === undefined"
	    >
	      <h4 class="alert-heading">
	        Error fetching module data
	      </h4>
	      <div class="alert-body">
	        No Module found with this id. Check
	        <b-link
	          class="alert-link"
	          :to="{ name: 'module-list'}"
	        >
	          Module List
	        </b-link>
	        for other modules.
	      </div>
	    </b-alert>

	    <template >

	      <b-row>
	        <b-col cols="12" >
	          <b-card>
					    <b-row v-if="moduleData">
					    	<b-col cols="12" xl="6" >
					        <table class="mt-2 mt-xl-0 w-100">
					          <tr>
					            <th class="pb-50">
					              <span class="font-weight-bold">Module Name</span>
					            </th>
					            <td class="pb-50 text-capitalize">
					              {{ moduleData.name }}
					            </td>
					          </tr>

					          <tr>
					            <th class="pb-50">
					              <span class="font-weight-bold">Status</span>
					            </th>
					            <td class="pb-50 text-capitalize">
					              <b-badge v-if="moduleData.is_active"
					                pill
					                variant="light-success"
					                class="text-capitalize"
					              >
					              	Active
					              </b-badge>
					              <b-badge v-else-if="!moduleData.is_active"
					                pill
					                variant="light-danger"
					                class="text-capitalize"
					              >
					                Archived
					              </b-badge>
					            </td>
					          </tr>
					        </table>
					      </b-col>

					      <b-col class="mt-1" cols="12" xl="12" >
						      <span class="font-weight-bold">Features:</span>
						      
	                <b-form-group>
	                  <b-form-checkbox-group
	                    id="checkbox-group-1"
	                    v-model="feature_ids"
	                    :options="featuresData"
	                    value-field="id"
	                    text-field="name"
	                    name="flavour-1"
	                    class="demo-inline-spacing"
	                    disabled
	                  />
	                </b-form-group>
		              
					    	</b-col>

			          <!-- Edit -->
			          <b-col cols="12" class="mt-2">
			            <div class="d-flex align-items-start justify-content-start">
			              <b-button
			                 v-if="$can('edit', 'Module')"
			                variant="primary"
			                :to="{ name: 'module-edit'}"
			                class="btn-sm"
			              >
			              	<feather-icon icon="EditIcon" />
			                <span class="align-middle ml-50">Edit</span>
			              </b-button>
			            </div>
			          </b-col>
					    </b-row>
					  </b-card>
	        </b-col>
	      </b-row>

	    </template>
	</b-card>
  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink, BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox, BFormGroup, BFormCheckboxGroup, BButton, BBadge,
} from 'bootstrap-vue'
import moduleStoreModule from '../moduleStoreModule'

export default {
  components: {
    BRow, BCol, BAlert, BLink,
    BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox, BFormGroup, BFormCheckboxGroup, BButton, BBadge,
  },
  setup() {
    const moduleData = ref(null)
    const feature_ids = ref(null)
    const featuresData = ref(null)

    const MODULE_APP_STORE_MODULE_NAME = 'app-module'

    // Register module
    if (!store.hasModule(MODULE_APP_STORE_MODULE_NAME)) store.registerModule(MODULE_APP_STORE_MODULE_NAME, moduleStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_APP_STORE_MODULE_NAME)) store.unregisterModule(MODULE_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-module/fetchData', { id: router.currentRoute.params.id })
      .then((response) => { 
          moduleData.value = response.data.data 
          feature_ids.value = response.data.feature_ids
      })
      .catch(error => {
        moduleData.value = undefined
      })

    store.dispatch('app-module/fetchFeatureOption')
        .then((response) => {
          if(response.data.status == 1)
            featuresData.value = response.data.data
      })

    return {
      moduleData,
      feature_ids,
      featuresData
    }
  },
}
</script>

<style>
	.demo-inline-spacing > * {
    margin-top: 1rem !important;
	}
</style>
